<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">BOOKING REPORT</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex gaps mv-10">
        <div class="box left center">
          <el-input placeholder="Search"
                    size="small"
                    ref="search"
                    v-model="search"
                    style="width: 200px;"
                    @input="handleSearchInput"
                    class="box center"></el-input>
        </div>
        <div>
          <el-date-picker
            :append-to-body="false"
            size="small"
            v-model="dateSearch"
            type="date"
            placeholder="Pick a day"
            @change="onChangeDate"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
    </div>
    <div :style="{width: tableWidth + 'px'}">
      <el-table
        :summary-method="getSummaries"
        show-summary
        :row-class-name="tableRowClassName"
        :max-height="tableHeight"
        border
        :data="filtered">
        <el-table-column
          width="150"
          label="Date">
          <template #default="scope">
            {{formatDateWithTime(scope.row.createDate)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="day"
          width="110"
          label="Day">
        </el-table-column>
        <el-table-column
          width="110"
          prop="session.time"
          label="Time">
        </el-table-column>
        <el-table-column
          prop="session.name"
          label="Session name">
        </el-table-column>
        <el-table-column
          width="180"
          label="Customer">
            <template #default="scope">
              <div class="info-text fs-12">{{scope.row.customer.first + ' ' + scope.row.customer.surname}}</div>
              <div class="fs-12">{{scope.row.customer.email}}</div>
              <div class="fs-12">{{scope.row.customer.phone}}</div>
            </template>
        </el-table-column>
        <el-table-column
          prop="ticket.name"
          label="Ticket type">
        </el-table-column>
        <el-table-column
          align="center"
          prop="ticket.persons"
          width="70"
          label="Pers">
        </el-table-column>
        <el-table-column
          align="rigth"
          prop="ticket.price"
          width="70"
          label="Price">
        </el-table-column>
        <el-table-column
          width="140"
          label="Discount">
            <template #default="scope">
              <div v-if="scope.row.discount.percent > 0">
                <span class="pr-6">{{scope.row.discount.percent}}%</span>
                <span class="fs-10 hint-text" v-if="scope.row.discount.isGroup">group</span>
                <span class="fs-10 hint-text" v-if="scope.row.discount.isPersonal">personal</span>
              </div>
            </template>
        </el-table-column>
        <el-table-column
          width="160"
          prop="addons"
          label="Addons">
          <template #default="scope">
            <div v-for="(a, index) in scope.row.addons" :key="index"
              class="fs-12 flex gaps">
              <span class="box grow justify-flex-start">{{a.name}}({{a.count}})</span>
              <span class="box justify-flex-end">{{a.cost * a.count}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          prop="amount"
          class-name="bg-primary-light"
          align="center"
          label="Amount">
        </el-table-column>
        <el-table-column
          width="100"
          prop="paymentStatus"
          align="center"
          label="Payment">
        </el-table-column>
        <el-table-column
          width="100"
          prop="useStatus"
          align="center"
          label="Use">
        </el-table-column>
        <el-table-column
          width="70"
          align="center"
          label="Refund">
            <template #default="scope">
              <i class="mdi mdi-check fs-20 success-text" v-if="scope.row.isRefund"></i>
            </template>
        </el-table-column>
        <el-table-column
          width="120">
          <template slot-scope="scope">
              <div class="flex gaps" v-if="scope.row.useStatus === 'booked'">
                  <el-button @click.native.prevent="editRow(scope.row)" type="text" size="small">
                      Edit
                  </el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'BookingReport',

  data () {
    return {
      dateSearch: new Date(),
      search: '',
      pickerOptions: {
        // shortcuts: [{
        //   text: 'Today',
        //   onClick (picker) {
        //     picker.$emit('pick', new Date())
        //   }
        // }, {
        //   text: 'Yesterday',
        //   onClick (picker) {
        //     const date = new Date()
        //     date.setTime(date.getTime() - 3600 * 1000 * 24)
        //     picker.$emit('pick', date)
        //   }
        // }, {
        //   text: '2 days ago',
        //   onClick (picker) {
        //     const date = new Date()
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 2)
        //     picker.$emit('pick', date)
        //   }
        // }, {
        //   text: '3 days ago',
        //   onClick (picker) {
        //     const date = new Date()
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 3)
        //     picker.$emit('pick', date)
        //   }
        // }, {
        //   text: '4 days ago',
        //   onClick (picker) {
        //     const date = new Date()
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 4)
        //     picker.$emit('pick', date)
        //   }
        // }, {
        //   text: '5 days ago',
        //   onClick (picker) {
        //     const date = new Date()
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 5)
        //     picker.$emit('pick', date)
        //   }
        // }, {
        //   text: '6 days ago',
        //   onClick (picker) {
        //     const date = new Date()
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 6)
        //     picker.$emit('pick', date)
        //   }
        // }, {
        //   text: '7 days ago',
        //   onClick (picker) {
        //     const date = new Date()
        //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
        //     picker.$emit('pick', date)
        //   }
        // }]
      }
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      innerWidth: state => state.nav.innerWidth,
      books: state => state.booking.books
    }),
    ...mapGetters([]),
    tableHeight () {
      return this.innerHeight - 181
    },
    tableWidth () {
      return this.innerWidth - 30
    },
    selectDay () {
      return this.formatDate(this.dateSearch)
    },
    filteredByDate () {
      return this.books.filter(i => this.formatDate(i.day) === this.selectDay)
    },
    filtered () {
      return this.filteredByDate.filter(item => {
        return this.search.length < 2 ||
         (item.customer.first.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.customer.surname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.customer.email.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.customer.phone.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      })
    }
  },

  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.isRefund) {
        return 'locked-panel'
      }
    },
    onChangeDate (v) {
      this.$store.dispatch('getBooksByDate', this.formatDate(this.dateSearch))
    },
    formatDate (date) {
      return new Date(new Date(date).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)
    },
    formatDateWithTime (date) {
      let iso = new Date(new Date(date).toString().split('GMT')[0] + ' UTC').toISOString()
      let arr = iso.split('T')
      return arr[0] + ', ' + arr[1].slice(0, 5)
    },
    handleSearchInput () {},
    editRow (row) {
      let id = row._id
      this.$onCommandParams('editBook', { id })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        const values = data.filter(d => !d.isRefund).map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  },
  mounted () {
    this.dateSearch = new Date()
  }
}
</script>

<style lang="scss">
</style>
