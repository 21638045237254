import { render, staticRenderFns } from "./BooksReport.vue?vue&type=template&id=2e9050d1&"
import script from "./BooksReport.vue?vue&type=script&lang=js&"
export * from "./BooksReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports